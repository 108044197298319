import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import createTheme from "@material-ui/core/styles/createTheme";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import customTheme from "./themes/project.json";
import Locals from "@micado-digital/react-ui/components/Locals";
import Ui from "@micado-digital/react-ui/components/Ui";
import UserData from "@micado-digital/react-ui/components/UserData";
import WebPageData from "@micado-digital/react-ui/components/WebPageData";
import WebProfile from "@micado-digital/react-ui/components/WebProfile";
import BookmarkRoot from "@micado-digital/react-bookmark/ReactBookmark/bookmarkRoot";
import Bookmark from "@micado-digital/react-shop/views/Bookmark01";
import BookmarkIcon from "@micado-digital/react-shop/components/BookmarkIcon";
import Cart from "@micado-digital/react-shop/views/Cart01";
import CartIcon from "@micado-digital/react-shop/components/CartIcon";
import CartOverlay from "@micado-digital/react-shop/Cart/CartOverlay";
import Checkout from "@micado-digital/react-shop/views/Checkout01";
import Confirmation from "@micado-digital/react-shop/views/Confirmation01";
import Login from "@micado-digital/react-shop/views/Login01";
import Logout from "@micado-digital/react-shop/components/Logout";
import MyAccount from "@micado-digital/react-shop/views/MyAccount01";
import ProductDetail from "@micado-digital/react-shop/views/ProductDetail01";
import ProductOverview from "@micado-digital/react-shop/views/ProductOverview01";
import Shop from "@micado-digital/react-shop/components/Shop";
import UserIcon from "@micado-digital/react-shop/components/UserIcon";

import "./css/index.css";

const lang = document.getElementsByTagName("html")[0].getAttribute("lang");
const theme = createTheme(customTheme);

const Core = ({ children }) => {
	return (
		<ThemeProvider theme={theme}>
			<Suspense fallback={<></>}>
				<CssBaseline />
				<BrowserRouter>
					<Ui>
						<WebPageData>
							<WebProfile>
								<UserData>
									<Shop lang={lang}>
										<BookmarkRoot>
											<Locals url={`/locals/${lang}.json`}>
												{children}
												<CartOverlay lang={lang} />
											</Locals>
										</BookmarkRoot>
									</Shop>
								</UserData>
							</WebProfile>
						</WebPageData>
					</Ui>
				</BrowserRouter>
			</Suspense>
		</ThemeProvider>
	);
};

if (process.env.NODE_ENV === "development") {
	ReactDOM.render(
		<Core>
			<Routes>
				<Route path="/de/shop/bookmark" element={<Bookmark />} />
				<Route path="/de/shop/login" element={<Login />} />
				<Route path="/de/shop/logout" element={<Logout />} />
				<Route path="/de/shop/cart" element={<Cart />} />
				<Route path="/de/shop/checkout/*" element={<Checkout />} />
				<Route path="/de/shop/confirmation" element={<Confirmation />} />
				<Route path="/de/shop/produkt/*" element={<ProductDetail />} />
				<Route path="/de/shop/myaccount/*" element={<MyAccount />} />
				<Route path="/*" element={<ProductOverview showCategories={false} />} />
			</Routes>
		</Core>,
		document.getElementById("root")
	);
}

if (process.env.NODE_ENV === "production") {
	const icons = document.getElementById("mco-shop-icons");

	if (icons) {
		ReactDOM.render(
			<Core>
				<div className="mco-shop-icons">
					<UserIcon />
					<BookmarkIcon />
					<CartIcon />
				</div>
			</Core>,
			icons
		);
	}

	const bookmark = document.getElementById("mco-shop-bookmark");

	if (bookmark) {
		ReactDOM.render(
			<Core>
				<Bookmark defaultLang={lang} />
			</Core>,
			bookmark
		);
	}

	const cart = document.getElementById("mco-shop-cart");

	if (cart) {
		ReactDOM.render(
			<Core>
				<Cart defaultLang={lang} />
			</Core>,
			cart
		);
	}

	const checkout = document.getElementById("mco-shop-checkout");

	if (checkout) {
		ReactDOM.render(
			<Core>
				<Routes>
					<Route path="/:lang/shop/checkout/*" element={<Checkout defaultLang={lang} />} />
				</Routes>
			</Core>,
			checkout
		);
	}

	const confirmation = document.getElementById("mco-shop-confirmation");

	if (confirmation) {
		ReactDOM.render(
			<Core>
				<Confirmation defaultLang={lang} />
			</Core>,
			confirmation
		);
	}

	const login = document.getElementById("mco-shop-login");

	if (login) {
		ReactDOM.render(
			<Core>
				<Login defaultLang={lang} />
			</Core>,
			login
		);
	}

	const logout = document.getElementById("mco-shop-logout");

	if (logout) {
		ReactDOM.render(
			<Core>
				<Logout />
			</Core>,
			logout
		);
	}

	const myAccount = document.getElementById("mco-shop-my-account");

	if (myAccount) {
		ReactDOM.render(
			<Core>
				<Routes>
					<Route path="/:lang/shop/myaccount/*" element={<MyAccount defaultLang={lang} />} />
				</Routes>
			</Core>,
			myAccount
		);
	}

	const productOverview = document.getElementById("mco-shop-product-overview");

	if (productOverview) {
		ReactDOM.render(
			<Core>
				<ProductOverview
					defaultLang={lang}
					selectedGroup={productOverview.getAttribute("data-group-id")}
					showCategories={false}
				/>
			</Core>,
			productOverview
		);
	}

	const productDetail = document.getElementById("mco-shop-product-detail");

	if (productDetail) {
		ReactDOM.render(
			<Core>
				<ProductDetail defaultLang={lang} />
			</Core>,
			productDetail
		);
	}
}
